import PropTypes from 'prop-types';
import cx from 'classnames';
import { FormattedMessage, FormattedRelativeTime } from 'react-intl';

import withNavigation from 'app/components/utils/withNavigation';
import Router from 'app/platform/Router';
import CommonColors from '@wsui/core/Colors';
import AlertFilledSvg from 'app/assets/icons/alert-filled.svg';
import CheckOnCircleSvg from 'app/assets/icons/check-on-circle.svg';
import useAnalytics from 'app/analytics/useAnalytics';
import * as Interaction from 'app/analytics/constants/Interaction';
import useCapabilities from 'app/hooks/useCapabilities';
import useAccountingSyncStatus from 'app/hooks/useAccountingSyncStatus';

import Utils from 'app/utils/Utils';
import styles from './SyncStatus.module.scss';

function SyncStatus ({ navigation, company }) {
    const { data, isError } = useAccountingSyncStatus();
    const { settingsIntegrations } = useCapabilities();
    const { trackInteraction } = useAnalytics();
    const navParams = ['Settings', {
        companyId: company?.id,
        tab: 'integrations',
    }];

    const goToSettings = (e) => {
        e.preventDefault();

        trackInteraction({
            type: Interaction.Type.Button,
            action: Interaction.Action.Click,
            element: 'Nav Sync Status',
        });

        navigation.navigate(...navParams);
    };

    if (data && !isError) {
        const hasEnoughInfo = data.stale !== undefined && data.lastSuccessfulSync !== undefined;
        const shouldShow = hasEnoughInfo && data.type === 'QBD';

        if (shouldShow) {
            const { stale: _stale, lastSuccessfulSync } = data;
            const stale = !!_stale;

            const timeAgo = <FormattedRelativeTime value={Utils.getTimeDiffForRelative(lastSuccessfulSync)} updateIntervalInSeconds={5} style="short" />;
            let icon = (
                <CheckOnCircleSvg width={24} height={24} color={CommonColors.surfie} />
            );

            if (stale) {
                icon = (
                    <AlertFilledSvg color={CommonColors.punch} width={24} height={24} />
                );
            }
            const content = <>
                {icon}
                <span className={styles.text}>
                    <FormattedMessage
                        defaultMessage='Last sync {timeAgo}'
                        description='Last successfully synced X time ago'
                        values={{ timeAgo }}
                    />
                </span>
            </>;

            if (settingsIntegrations.canView) {
                return (
                    <a
                        onClick={goToSettings}
                        className={cx(styles.button, styles.interactive, { [styles.stale]: stale })}
                        href={Router.generatePath(...navParams)}
                    >
                        {content}
                    </a>
                );
            } else {
                return (
                    <span
                        className={cx(styles.button, { [styles.stale]: stale })}
                    >
                        {content}
                    </span>
                );
            }

        }
    } else if (isError) {
        return (
            <span className={cx(styles.button, styles.warning)}>
                <AlertFilledSvg color={CommonColors.punch} width={24} height={24} />
                <span className={styles.text}>
                    <FormattedMessage
                        defaultMessage="Can't load sync status"
                        description='Failed to load sync status'
                    />
                </span>
            </span>
        );
    }

    return null;
}

SyncStatus.propTypes = {
    company: PropTypes.object,
    navigation: PropTypes.object.isRequired,
};

export default withNavigation(SyncStatus);
