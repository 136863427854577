import { FormattedMessage } from 'react-intl';

import YourInformationSvg from 'app/assets/illustrations/buyer-onboarding/your_information.svg';
import YourCompanySvg from 'app/assets/illustrations/buyer-onboarding/your_company.svg';
import TradeReferencesSvg from 'app/assets/illustrations/buyer-onboarding/trade_references.svg';
import TermsSvg from 'app/assets/illustrations/buyer-onboarding/terms.svg';
import PaymentSvg from 'app/assets/illustrations/buyer-onboarding/payment.svg';
import CheckOnCircleSvg from 'app/assets/icons/check-on-circle.svg';

import Badge from 'app/components/common/Badge';

import sectionStyles from './sections/Section.module.scss';

import { AVAILABLE_SECTIONS, OMIT } from 'app/constants/Onboarding';
import ValidationBadge from '../common/ValidationBadge';

export const SECTION_MAP = {
    primaryUser: {
        Icon: YourInformationSvg,
        label: <FormattedMessage
            defaultMessage='Your information'
            description='Buyer onboarding landing page - your information section title'
        />,
        header: true,
    },
    company: {
        Icon: YourCompanySvg,
        label: <FormattedMessage
            defaultMessage='Your company'
            description='Buyer onboarding landing page - your company section title'
        />,
        header: true,
    },
    tradeRefs: {
        Icon: TradeReferencesSvg,
        label: <FormattedMessage
            defaultMessage='Trade references'
            description='Buyer onboarding landing page - trade references section title'
        />,
        header: true,
    },
    paymentAccounts: {
        Icon: PaymentSvg,
        label: <FormattedMessage
            defaultMessage='Payment'
            description='Buyer onboarding landing page - payment section title'
        />,
        header: true,
    },
    terms: {
        Icon: TermsSvg,
        label: <FormattedMessage
            defaultMessage='Agree to terms'
            description='Buyer onboarding landing page - terms section title'
        />,
        header: true,
    },
    submitted: {
        header: false,
    },
};

export const requiredErrorMessage = <FormattedMessage
    defaultMessage='This field is required'
    description='Form error message for a required field'
/>;

export const invalidPhoneNumberMessage = <FormattedMessage
    defaultMessage='Please enter a valid US or Canadian phone number.'
    description='Message when a non US or Canadian phone number as been entered'
/>;

export const invalidEmailMessage = <FormattedMessage
    defaultMessage='Valid email address required'
    description='Validation message when email address is invalid'
/>;

export const validationErrors = {
    required: requiredErrorMessage,
    phoneNumber: invalidPhoneNumberMessage,
    email: invalidEmailMessage,
};

export const validationCodes = {
    invalidPhone: 'invalidPhone',
};

export const validationRegexes = {
    phoneNumber: /^[^+]\d*/,
};

export function getBadge({required, optional, isValid, direction, className}) {
    if (!direction) {
        return <ValidationBadge
            type={required ? ValidationBadge.types.REQUIRED :  ValidationBadge.types.OPTIONAL}
            isValid={isValid}
            className={className}
        />;
    }
    if (optional) {
        return <Badge level='default' className={sectionStyles.badge} direction={direction}>
            <FormattedMessage
                defaultMessage='Optional'
                description='Optional badge'
            />
        </Badge>;
    } else if (required) {
        return isValid ? <CheckOnCircleSvg width={18} height={18} className={sectionStyles.icon} />
            : <Badge level='warning' className={sectionStyles.badge} direction={direction}>
                <FormattedMessage
                    defaultMessage='Required'
                    description='Required badge'
                />
            </Badge>;
    }
}

export function getSections(config) {
    return AVAILABLE_SECTIONS.filter(av => config[av]?.req !== OMIT).map((av, index) => ({
        ...SECTION_MAP[av],
        index,
        name: av,
    }));
}

