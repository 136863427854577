import { useContext, useEffect, useMemo } from 'react';
import { useQuery } from 'react-query';
import { ActiveCompanyIdContext } from 'app/components/utils/ActiveCompanyProvider';
import { IntlContext } from 'app/components/utils/IntlProvider';
import { FEATURES } from 'app/models/ActiveCompany';
import fetchActiveCompany from 'app/fetch/fetchActiveCompany';
import useActiveUser from 'app/hooks/useActiveUser';
import useNavigation from 'app/hooks/useNavigation';

export default function useActiveCompany () {
    const { setSellerTz } = useContext(IntlContext);
    const { activeCompanyId, clearActiveCompanyId } = useContext(ActiveCompanyIdContext);
    const { isSignedIn, signOut } = useActiveUser();
    const { navigate } = useNavigation();

    const companyId = isSignedIn ? activeCompanyId : null;
    const { data, isError, error, refetch } = useQuery(['activeCompany', companyId], fetchActiveCompany, {
        enabled: !!companyId,
        useErrorBoundary: false,
    });

    useEffect(() => {
        setSellerTz(data?.seller?.timezone || data?.timeZone);
    }, [data, setSellerTz]);

    // Company id isn't found, clear from cookie and navigate away
    if (error?.isErrorName(['NotFoundException', 'BadRequestException'])) {
        clearActiveCompanyId();
        navigate('Default');
    }

    // Navigate back to signin screen if they don't have 'login' access for the companyId
    if (error?.isErrorName('PermissionDeniedException')) {
        navigate('SignIn', { next: null });
    }

    if (error?.isErrorName('MaintenanceException')) {
        throw error;
    }

    if (error?.isErrorName('RestrictedSessionException')) {
        signOut();
    }

    return useMemo(() => ({
        activeCompany: data,
        activeCompanyId: companyId,
        isError,
        refetch,
        FEATURES,
    }), [data, companyId, isError, refetch]);
}
