import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import CompanySelection from 'app/components/modals/CompanySelection';
import RestrictedSessionModal from 'app/components/modals/RestrictedSession';
import * as Interaction from 'app/analytics/constants/Interaction';
import Testing from 'app/utils/Testing';

import useActiveUser from 'app/hooks/useActiveUser';
import useBreakpoints from 'app/hooks/useBreakpoints';
import useAnalytics from 'app/analytics/useAnalytics';
import useNavigation from 'app/hooks/useNavigation';

import styles from './SwitchCompany.module.scss';

function SwitchCompany ({
    company,
}) {
    const { activeUser, isRestricted } = useActiveUser();
    const { isDesktopWeb } = useBreakpoints();
    const { state, replace } = useNavigation();
    const [visible, setVisible] = useState(state.params.showSwitcher);
    const { trackInteraction } = useAnalytics();

    const onClose = () => setVisible(false);
    const onOpen = () => {
        trackInteraction({
            type: Interaction.Type.Link,
            action: Interaction.Action.Click,
            element: 'Switch Company',
        });
        if (isRestricted) {
            replace(state.routeName, {
                ...state.params,
                showSwitcher: true,
            });
        }
        setVisible(true);
    };

    const handleCloseLogin = ({ success } = {}) => {
        success && setVisible(true);
        if (!success) {
            setVisible(false);
            const newParams = {...state.params};
            delete newParams.showSwitcher;
            replace(state.routeName, newParams);
        }
    };

    const isSellerView = company?.isSeller;
    const showSwitch = activeUser?.canSwitchCompanies;

    const switchButton = showSwitch ? (
        <span className={styles.switch}>
            <FormattedMessage
                defaultMessage='Switch'
                description='Action text to switch to a different company'
            />
        </span>
    ) : undefined;
    const companyName = <span>{company?.displayName}{(isSellerView || !isDesktopWeb) && switchButton}</span>;
    const sellerName = isDesktopWeb && !isSellerView && <div className={styles.sellerName}>
        {`  \u21C4 ${company?.seller?.displayName || ''}`}
        {switchButton}
    </div>;

    const content = showSwitch ?
        <div
            className={styles.company}
            role='button'
            onClick={onOpen}
            {...Testing.testAttribute('switchCompany')}
        >
            {companyName} {sellerName}
        </div> :
        <div className={styles.company}>{companyName}{sellerName}</div>;

    return (
        <React.Fragment>
            {content}
            <CompanySelection visible={visible && !isRestricted} onClose={onClose} />
            <RestrictedSessionModal
                visible={isRestricted && visible}
                currentUser={{
                    username: activeUser?.accountId,
                    passwordSet: activeUser?.passwordSet,
                }}
                onClose={handleCloseLogin}
            />
        </React.Fragment>
    );

}

SwitchCompany.propTypes = {
    company: PropTypes.object,
};

export default SwitchCompany;
