import * as PageView from 'app/analytics/constants/PageView';
import OnboardingScreenFrame from 'app/components/onboarding/new-customer-form/OnboardingScreenFrame';

export const routes = {
    SignIn: {
        unauthenticated: true,
        pattern: '/signin',
        analytics: {
            type: PageView.Type.Setting,
            entity: PageView.Entity.User,
        },
    },
    BrandedSignIn: {
        unauthenticated: true,
        pattern: '/portal/:slug',
        analytics: {
            type: PageView.Type.Setting,
            entity: PageView.Entity.User,
        },
    },
    OnboardingLanding: {
        unauthenticated: true,
        pattern: '/portal/:companySlug/form/:applicationSlug',
        analytics: {
            type: PageView.Type.Wizard,
            entity: PageView.Entity.CustomerForm,
        },
        props: {
            errorBehavior: 'refresh',
            errorSignOutLocation: 'OnboardingLanding',
            errorSignOutParams: ['companySlug', 'applicationSlug'],
        },
    },
    OnboardingApplicationPreview: {
        pattern: '/portal/:companySlug/preview/:applicationSlug',
        analytics: {
            type: PageView.Type.Wizard,
            entity: PageView.Entity.CustomerForm,
        },
        ScreenFrame: OnboardingScreenFrame,
        props: {
            isSellerPreview: true,
        },
    },
    OnboardingApplicationSingle: {
        pattern: '/portal/:companySlug/form/:applicationSlug/:applicationId/view',
        analytics: {
            type: PageView.Type.Detail,
            entity: PageView.Entity.CustomerForm,
        },
        validate: {
            applicationId: { type: 'uuid' },
        },
        ScreenFrame: OnboardingScreenFrame,
        unauthenticatedRedirect: 'OnboardingLanding',
        props: {
            isViewOnly: true,
            errorBehavior: 'refresh',
            errorSignOutLocation: 'OnboardingLanding',
            errorSignOutParams: ['companySlug', 'applicationSlug'],
        },
    },
    OnboardingApplication: {
        pattern: '/portal/:companySlug/form/:applicationSlug/:applicationId',
        analytics: {
            type: PageView.Type.Wizard,
            entity: PageView.Entity.CustomerForm,
        },
        validate: {
            applicationId: { type: 'uuid' },
        },
        ScreenFrame: OnboardingScreenFrame,
        unauthenticatedRedirect: 'OnboardingLanding',
        props: {
            errorBehavior: 'refresh',
            errorSignOutLocation: 'OnboardingLanding',
            errorSignOutParams: ['companySlug', 'applicationSlug'],
        },
    },
    OnboardingPlaidLink: {
        pattern: '/portal/:companySlug/form/:applicationSlug/:applicationId/plaid-link',
        analytics: {
            type: PageView.Type.Setting,
            entity: PageView.Entity.Company,
        },
        validate: {
            applicationId: { type: 'uuid' },
        },
    },
    Settings: {
        pattern: '/companies/:companyId/settings',
        activeNavTab: 'Settings',
        analytics: {
            type: PageView.Type.Setting,
            entity: PageView.Entity.Company,
        },
        validate: {
            companyId: { type: 'uuid' },
        },
    },
    Preferences: {
        pattern: '/companies/:companyId/preferences',
        activeNavTab: 'Preferences',
        analytics: {
            type: PageView.Type.Setting,
            entity: PageView.Entity.Company,
        },
        validate: {
            companyId: { type: 'uuid' },
        },
    },
    Applications: {
        pattern: '/companies/:companyId/forms',
        activeNavTab: 'NewCustomerForm',
        analytics: {
            type: PageView.Type.List,
            entity: PageView.Entity.CustomerForm,
        },
        validate: {
            companyId: { type: 'uuid' },
        },
    },
    Application: {
        pattern: '/companies/:companyId/forms/:applicationId',
        activeNavTab: 'Customers',
        analytics: {
            type: PageView.Type.List,
            entity: PageView.Entity.CustomerForm,
        },
        validate: {
            companyId: { type: 'uuid' },
            applicationId: { type: 'uuid' },
        },
    },
    Customers: {
        props: {
            label: 'Customer',
        },
        pattern: '/companies/:companyId/customers',
        activeNavTab: 'Customers',
        analytics: {
            type: PageView.Type.List,
            entity: PageView.Entity.Company,
        },
        validate: {
            companyId: { type: 'uuid' },
        },
    },
    CustomerSingle: {
        props: {
            label: 'Customer',
            includeCart: false,
            isSellerView: true,
            singleView: 'CustomerInvoiceSingle',
            parentView: 'Customers',
            traderView: 'VendorSingle',
        },
        pattern: '/companies/:companyId/customers/:traderId',
        activeNavTab: 'Customers',
        analytics: {
            type: PageView.Type.Single,
            entity: PageView.Entity.Company,
        },
        validate: {
            companyId: { type: 'uuid' },
            traderId: { type: 'uuid' },
        },
    },
    CustomerAddPayment: {
        props: {
            isSellerView: true,
        },
        pattern: '/companies/:companyId/customers/:traderId/add-payment',
        activeNavTab: 'Customers',
        analytics: {
            type: PageView.Type.Wizard,
            entity: PageView.Entity.Payment,
        },
        validate: {
            companyId: { type: 'uuid' },
            traderId: { type: 'uuid' },
        },
    },
    CustomerSettings: {
        props: {
            isSellerView: true,
        },
        pattern: '/companies/:companyId/customers/:traderId/settings',
        activeNavTab: 'Customers',
        analytics: {
            type: PageView.Type.Setting,
            entity: PageView.Entity.Company,
        },
        validate: {
            companyId: { type: 'uuid' },
            traderId: { type: 'uuid' },
        },
    },
    NewCustomerFormConfigSingle: {
        pattern: '/companies/:companyId/new-customer-form/config/:configId',
        activeNavTab: 'Settings',
        analytics: {
            type: PageView.Type.Setting,
            entity: PageView.Entity.Company,
        },
        validate: {
            companyId: { type: 'uuid' },
            configId: { type: 'uuid' },
        },
    },
    Vendors: {
        props: {
            label: 'Vendor',
        },
        pattern: '/companies/:companyId/vendors',
        activeNavTab: 'Vendors',
        analytics: {
            type: PageView.Type.List,
            entity: PageView.Entity.Company,
        },
        validate: {
            companyId: { type: 'uuid' },
        },
    },
    VendorSingle: {
        props: {
            label: 'Vendor',
            isSellerView: false,
            singleView: 'VendorInvoiceSingle',
            traderView: 'CustomerSingle',
        },
        pattern: '/companies/:companyId/vendors/:traderId',
        activeNavTab: 'Vendors',
        analytics: {
            type: PageView.Type.Single,
            entity: PageView.Entity.Company,
        },
        validate: {
            companyId: { type: 'uuid' },
            traderId: { type: 'uuid' },
        },
    },
    Cart: {
        pattern: '/companies/:companyId/cart',
        activeNavTab: 'Vendors',
        analytics: {
            type: PageView.Type.Detail,
            entity: PageView.Entity.Cart,
        },
        validate: {
            companyId: { type: 'uuid' },
        },
    },
    SellerCart: {
        props: { isSellerView: true },
        pattern: '/companies/:companyId/customers/:traderId/cart',
        activeNavTab: 'Customers',
        analytics: {
            type: PageView.Type.Detail,
            entity: PageView.Entity.Cart,
        },
        validate: {
            companyId: { type: 'uuid' },
            traderId: { type: 'uuid' },
        },
    },
    CartPayment: {
        pattern: '/companies/:companyId/cart/:paymentId',
        activeNavTab: 'Vendors',
        analytics: {
            type: PageView.Type.Detail,
            entity: PageView.Entity.Cart,
        },
        validate: {
            companyId: { type: 'uuid' },
            paymentId: { type: 'uuid' },
        },
    },
    SellerCartPayment: {
        props: { isSellerView: true },
        pattern: '/companies/:companyId/customers/:traderId/cart/:paymentId',
        activeNavTab: 'Customers',
        analytics: {
            type: PageView.Type.Detail,
            entity: PageView.Entity.Cart,
        },
        validate: {
            companyId: { type: 'uuid' },
            traderId: { type: 'uuid' },
            paymentId: { type: 'uuid' },
        },
    },
    PaymentsIn: {
        props: {hasCustomers: true},
        pattern: '/companies/:companyId/payments-in',
        activeNavTab: 'PaymentsIn',
        analytics: {
            type: PageView.Type.List,
            entity: PageView.Entity.Payment,
        },
        validate: {
            companyId: { type: 'uuid' },
        },
    },
    PaymentsOut: {
        props: {hasCustomers: false},
        pattern: '/companies/:companyId/payments-out',
        activeNavTab: 'PaymentsOut',
        analytics: {
            type: PageView.Type.List,
            entity: PageView.Entity.Payment,
        },
        validate: {
            companyId: { type: 'uuid' },
        },
    },
    PaymentInSingle: {
        props: {
            isSellerView: true,
            parentView: 'PaymentsIn',
            traderView: 'PaymentOutSingle',
        },
        pattern: '/companies/:companyId/payments-in/:paymentId',
        activeNavTab: 'PaymentsIn',
        analytics: {
            type: PageView.Type.Single,
            entity: PageView.Entity.Payment,
        },
        validate: {
            companyId: { type: 'uuid' },
            paymentId: { type: 'uuid' },
        },
    },
    PaymentOutSingle: {
        props: {
            isSellerView: false,
            parentView: 'PaymentsOut',
            traderView: 'PaymentInSingle',
        },
        pattern: '/companies/:companyId/payments-out/:paymentId',
        activeNavTab: 'PaymentsOut',
        analytics: {
            type: PageView.Type.Single,
            entity: PageView.Entity.Payment,
        },
        validate: {
            companyId: { type: 'uuid' },
            paymentId: { type: 'uuid' },
        },
    },
    Payouts: {
        props: {
            parentView: 'PaymentsIn',
        },
        pattern: '/companies/:companyId/payouts/',
        activeNavTab: 'PaymentsIn',
        analytics: {
            type: PageView.Type.List,
            entity: PageView.Entity.Payment,
        },
        validate: {
            companyId: { type: 'uuid' },
        },
    },
    PayoutSingle: {
        pattern: '/companies/:companyId/payouts/:paymentId',
        activeNavTab: 'PaymentsIn',
        analytics: {
            type: PageView.Type.Single,
            entity: PageView.Entity.Payment,
        },
        validate: {
            companyId: { type: 'uuid' },
        },
    },
    Invoices: {
        props: {
            isSellerView: true,
            singleView: 'InvoiceListInvoiceSingle',
        },
        pattern: '/companies/:companyId/invoices',
        activeNavTab: 'Invoices',
        analytics: {
            type: PageView.Type.List,
            entity: PageView.Entity.Invoice,
        },
        validate: {
            companyId: { type: 'uuid' },
        },
    },
    CustomerInvoiceSingle: {
        props: {
            isSellerView: true,
            parentView: 'CustomerSingle',
            traderView: 'VendorInvoiceSingle',
        },
        pattern: '/companies/:companyId/customers/invoices/:invoiceId',
        activeNavTab: 'Customers',
        analytics: {
            type: PageView.Type.Single,
            entity: PageView.Entity.Invoice,
        },
        validate: {
            companyId: { type: 'uuid' },
            invoiceId: { type: 'uuid' },
        },
    },
    VendorInvoiceSingle: {
        props: {
            isSellerView: false,
            parentView: 'VendorSingle',
            traderView: 'CustomerInvoiceSingle',
        },
        pattern: '/companies/:companyId/vendors/invoices/:invoiceId',
        activeNavTab: 'Vendors',
        analytics: {
            type: PageView.Type.Single,
            entity: PageView.Entity.Invoice,
        },
        validate: {
            companyId: { type: 'uuid' },
            invoiceId: { type: 'uuid' },
        },
    },
    InvoiceListInvoiceSingle: {
        props: {
            isSellerView: true,
            parentView: 'Invoices',
            traderView: 'VendorInvoiceSingle',
        },
        pattern: '/companies/:companyId/invoices/:invoiceId',
        activeNavTab: 'Invoices',
        analytics: {
            type: PageView.Type.List,
            entity: PageView.Entity.Invoice,
        },
        validate: {
            companyId: { type: 'uuid' },
            invoiceId: { type: 'uuid' },
        },
    },
    CustomerSingleCustomerPaymentPlan: {
        props: {
            label: 'Customer',
            isSellerView: true,
            parentView: 'CustomerSingle',
            traderView: 'VendorSingleVendorPaymentPlan',
        },
        pattern: '/companies/:companyId/customers/:traderId/autopay',
        activeNavTab: 'Customers',
        analytics: {
            type: PageView.Type.Detail,
            entity: PageView.Entity.PaymentPlan,
        },
        validate: {
            companyId: { type: 'uuid' },
            traderId: { type: 'uuid' },
        },
    },
    CustomerSingleCustomerPaymentPlanSetup: {
        props: {
            label: 'Customer',
            isSellerView: true,
            isSellerProposal: false,
            parentView: 'CustomerSingleCustomerPaymentPlan',
            traderView: 'VendorSingleVendorPaymentPlan',
        },
        pattern: '/companies/:companyId/customers/:traderId/autopay/setup',
        activeNavTab: 'Customers',
        analytics: {
            type: PageView.Type.Detail,
            entity: PageView.Entity.PaymentPlan,
        },
        validate: {
            companyId: { type: 'uuid' },
            traderId: { type: 'uuid' },
        },
    },
    CustomerSingleCustomerPaymentPlanSetupWithExisting: {
        props: {
            label: 'Customer',
            isSellerView: true,
            isSellerProposal: false,
            parentView: 'CustomerSingleCustomerPaymentPlan',
            traderView: 'VendorSingleVendorPaymentPlan',
        },
        pattern: '/companies/:companyId/customers/:traderId/autopay/setup/:paymentPlanId',
        activeNavTab: 'Customers',
        analytics: {
            type: PageView.Type.Detail,
            entity: PageView.Entity.PaymentPlan,
        },
        validate: {
            companyId: { type: 'uuid' },
            traderId: { type: 'uuid' },
            paymentPlanId: { type: 'uuid' },
        },
    },
    CustomerSingleCustomerPaymentPlanPropose: {
        props: {
            label: 'Customer',
            isSellerView: true,
            isSellerProposal: true,
            parentView: 'CustomerSingleCustomerPaymentPlan',
            traderView: 'VendorSingleVendorPaymentPlan',
        },
        pattern: '/companies/:companyId/customers/:traderId/autopay/propose',
        activeNavTab: 'Customers',
        analytics: {
            type: PageView.Type.Detail,
            entity: PageView.Entity.PaymentPlan,
        },
        validate: {
            companyId: { type: 'uuid' },
            traderId: { type: 'uuid' },
        },
    },
    CustomerSingleCustomerPaymentPlanTerms: {
        props: {
            label: 'Customer',
            isSellerView: true,
            isSellerProposal: false,
            parentView: 'CustomerSingleCustomerPaymentPlanSetupWithExisting',
            traderView: 'VendorSingleVendorPaymentPlan',
        },
        pattern: '/companies/:companyId/customers/:traderId/autopay/:paymentPlanId/terms',
        activeNavTab: 'Customers',
        analytics: {
            type: PageView.Type.Detail,
            entity: PageView.Entity.PaymentPlan,
        },
        validate: {
            companyId: { type: 'uuid' },
            traderId: { type: 'uuid' },
            paymentPlanId: { type: 'uuid' },
        },
    },
    PaymentPlanListCustomerPaymentPlan: {
        props: {
            label: 'Customer',
            isSellerView: true,
            parentView: 'Autopay',
            traderView: 'VendorSingleVendorPaymentPlan',
        },
        pattern: '/companies/:companyId/autopay-customers/:traderId/autopay',
        activeNavTab: 'Autopay',
        analytics: {
            type: PageView.Type.Detail,
            entity: PageView.Entity.PaymentPlan,
        },
        validate: {
            companyId: { type: 'uuid' },
            traderId: { type: 'uuid' },
        },
    },
    VendorSingleVendorPaymentPlan: {
        props: {
            label: 'Vendor',
            isSellerView: false,
            parentView: 'VendorSingle',
            traderView: 'CustomerSingleCustomerPaymentPlan',
        },
        pattern: '/companies/:companyId/vendors/:traderId/autopay',
        activeNavTab: 'AutopayOut',
        analytics: {
            type: PageView.Type.Detail,
            entity: PageView.Entity.PaymentPlan,
        },
        validate: {
            companyId: { type: 'uuid' },
            traderId: { type: 'uuid' },
        },
    },
    VendorSingleVendorPaymentPlanSetup: {
        props: {
            label: 'Vendor',
            isSellerView: false,
            parentView: 'VendorSingleVendorPaymentPlan',
            traderView: 'CustomerSingleCustomerPaymentPlanSetup',
        },
        pattern: '/companies/:companyId/vendors/:traderId/autopay/setup',
        activeNavTab: 'AutopayOut',
        analytics: {
            type: PageView.Type.Detail,
            entity: PageView.Entity.PaymentPlan,
        },
        validate: {
            companyId: { type: 'uuid' },
            traderId: { type: 'uuid' },
        },
    },
    VendorSingleVendorPaymentAcceptSetup: {
        props: {
            label: 'Vendor',
            isSellerView: false,
            parentView: 'VendorSingleVendorPaymentPlan',
            traderView: 'CustomerSingleCustomerPaymentPlanSetup',
        },
        pattern: '/companies/:companyId/vendors/:traderId/autopay/setup/:paymentPlanId',
        activeNavTab: 'AutopayOut',
        analytics: {
            type: PageView.Type.Detail,
            entity: PageView.Entity.PaymentPlan,
        },
        validate: {
            companyId: { type: 'uuid' },
            traderId: { type: 'uuid' },
            paymentPlanId: { type: 'uuid' },
        },
    },
    VendorSingleVendorPaymentPlanTerms: {
        props: {
            label: 'Vendor',
            isSellerView: false,
            parentView: 'VendorSingleVendorPaymentAcceptSetup',
            traderView: 'CustomerSingleCustomerPaymentPlanTerms',
        },
        pattern: '/companies/:companyId/vendors/:traderId/autopay/:paymentPlanId/terms',
        activeNavTab: 'AutopayOut',
        analytics: {
            type: PageView.Type.Detail,
            entity: PageView.Entity.PaymentPlan,
        },
        validate: {
            companyId: { type: 'uuid' },
            traderId: { type: 'uuid' },
            paymentPlanId: { type: 'uuid' },
        },
    },
    Messages: {
        props: {
            label: 'Message',
            isSellerView: true,
        },
        pattern: '/companies/:companyId/messages',
        activeNavTab: 'Messages',
        analytics: {
            type: PageView.Type.List,
            entity: PageView.Entity.Message,
        },
        validate: {
            companyId: { type: 'uuid' },
        },
    },
    Autopay: {
        props: {
            label: 'Customer',
            isSellerView: true,
        },
        pattern: '/companies/:companyId/autopay-customers',
        activeNavTab: 'Autopay',
        analytics: {
            type: PageView.Type.List,
            entity: PageView.Entity.Company,
        },
        validate: {
            companyId: { type: 'uuid' },
        },
    },
    Invite: {
        pattern: '/companies/:companyId/invite',
        analytics: {
            type: PageView.Type.List,
            entity: PageView.Entity.ProspectSeller,
        },
        validate: {
            companyId: { type: 'uuid' },
        },
    },
    PlaidLink: {
        pattern: '/companies/:companyId/plaid-link',
        analytics: {
            type: PageView.Type.Setting,
            entity: PageView.Entity.Company,
        },
        validate: {
            companyId: { type: 'uuid' },
        },
    },
    PowerUser: {
        pattern: '/power',
    },
    StripeLink: {
        pattern: '/stripe-link',
        activeNavTab: 'Settings',
        analytics: {
            type: PageView.Type.Setting,
            entity: PageView.Entity.Company,
        },
    },
    StripeLinkV2: {
        pattern: '/stripe-account-link-return',
        activeNavTab: 'Settings',
        analytics: {
            type: PageView.Type.Setting,
            entity: PageView.Entity.Company,
        },
    },
    StripeLinkRefreshV2: {
        pattern: '/stripe-account-link-refresh',
        activeNavTab: 'Settings',
        analytics: {
            type: PageView.Type.Setting,
            entity: PageView.Entity.Company,
        },
    },
    PlaidLinkBouncer: {
        pattern: '/plaid-link',
        unauthenticated: true,
        analytics: {
            type: PageView.Type.Setting,
            entity: PageView.Entity.Company,
        },
    },
    Welcome: {
        pattern: '/companies/:companyId/welcome',
        analytics: {
            type: PageView.Type.Detail,
            entity: PageView.Entity.Message,
        },
        validate: {
            companyId: { type: 'uuid' },
        },
    },
    BuyerUpsell: {
        pattern: '/companies/:companyId/manage-ar',
        activeNavTab: 'BuyerUpsell',
        analytics: {
            type: PageView.Type.Detail,
            entity: PageView.Entity.ProspectSeller,
        },
        validate: {
            companyId: { type: 'uuid' },
        },
    },
    AccountChangeEmail: {
        pattern: '/account/change-email',
        unauthenticated: true,
        analytics: {
            type: PageView.Type.Setting,
            entity: PageView.Entity.User,
        },
    },
    AccountChangeContact: {
        pattern: '/account/change-contact',
        unauthenticated: true,
        analytics: {
            type: PageView.Type.Setting,
            entity: PageView.Entity.User,
        },
    },
    BaseCompany: {
        pattern: '/companies/:companyId',
        validate: {
            companyId: { type: 'uuid' },
        },
    },
    Logout: {
        unauthenticated: true,
        pattern: '/logout',
    },
    SuperAdminNotFound: {
        unauthenticated: true,
        pattern: '/superadmin*',
    },
    Default: {
        unauthenticated: true,
        pattern: '/',
    },
};

// Add the key to the object for easier filtering
Object.keys(routes).forEach(routeName => {
    routes[routeName].routeName = routeName;
});

export default {
    routes,
};
