import PropTypes from 'prop-types';
import cx from 'classnames';
import CompanyLogo from 'app/components/common/CompanyLogo';
import Testing from 'app/utils/Testing';
import styles from './Header.module.scss';

function Header ({ logoUri, title, description, className, isTopLevel }) {

    if (!logoUri && !title && !description) {
        return null;
    }

    return (
        <div className={cx(styles.wrapper, className)}>
            {logoUri && <div className={styles.companyLogoWrapper}>
                <CompanyLogo uri={logoUri } />
            </div>}
            <div className={cx(styles.textWrapper, { [styles.topLevel]: isTopLevel })}>
                <h1 {...Testing.testAttribute('screenTitle')}>
                    {title}
                </h1>
                {description && <div className={styles.description}>{description}</div>}
            </div>
        </div>
    );
}

Header.propTypes = {
    logoUri: PropTypes.string,
    title: PropTypes.node,
    description: PropTypes.node,
    className: PropTypes.string,
    isTopLevel: PropTypes.bool,
};

export default Header;
