import { useCallback } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import useActiveUser from 'app/hooks/useActiveUser';
import useNavigation from 'app/hooks/useNavigation';
import * as Fetch from 'app/utils/Fetch';
import User from 'app/utils/User';
import fetchCustomerForm from 'app/fetch/fetchCustomerForm';

const fetchOnboardingConfig = async ({ queryKey }) => {
    const [, companySlug, applicationSlug] = queryKey;
    const { body } = await Fetch.unauthenticatedFetch(
        'GET',
        `/company/onboarding/config/${companySlug}/${applicationSlug}`,
    );

    return body;
};

const previewInitialData = {
    onboarding: {
        additionalUsers: [],
        tradeRefs: [],
    },
};

export default function useBuyerOnboarding () {
    const { state: navState } = useNavigation();
    const previewMode = navState.routeParams.isSellerPreview;

    const queryClient = useQueryClient();
    const { isSignedIn } = useActiveUser();
    const { companySlug, applicationSlug, applicationId } = navState.params;

    const queryKey = [
        'buyerOnboardingConfig',
        companySlug,
        applicationSlug,
    ];

    const { data, isLoading } = useQuery(
        queryKey,
        fetchOnboardingConfig,
        { refetchOnMount : true, useErrorBoundary: true }
    );

    const applicationQueryKey = ['buyerInProgressForm', applicationId];
    const { data: formData, isLoading: formIsLoading, refetch: refetchApplication } = useQuery(
        applicationQueryKey,
        fetchCustomerForm,
        {
            refetchOnMount: true,
            useErrorBoundary: true,
            enabled: !!applicationId && !previewMode && isSignedIn,
        }
    );

    const updateApplicationSection = useCallback(async (section) => {
        return User.authenticatedFetch(
            null,
            `/company/onboarding/${applicationId}/update`,
            'POST',
            section
        );
    }, [applicationId]);

    const addStripeAccount = useCallback(async (messageBody) => {
        return User.authenticatedFetch(
            undefined,
            `/company/onboarding/${applicationId}/accounts/stripe`,
            'POST',
            messageBody,
        );
    }, [applicationId]);

    const linkExistingPaymentAccount = useCallback(async ({paymentAccountId}) => {
        return User.authenticatedFetch(
            undefined,
            `/company/onboarding/${applicationId}/accounts/linkExisting`,
            'POST',
            { paymentAccountId },
        );
    }, [applicationId]);

    const removePaymentAccount = useCallback(async (accountId) => {
        return User.authenticatedFetch(
            undefined,
            `/company/onboarding/${applicationId}/accounts/${accountId}`,
            'DELETE'
        );
    }, [applicationId]);

    const updatePaymentAccount = useCallback(async ({paymentAccountId, sellerUseEnabled}) => {
        return User.authenticatedFetch(
            undefined,
            `/company/onboarding/${applicationId}/accounts/${paymentAccountId}/update`,
            'POST',
            { sellerUseEnabled },
        );
    }, [applicationId]);

    const agreeToPaymentPlan = useCallback(async ({paymentAccountId}) => {
        return User.authenticatedFetch(
            undefined,
            `/company/onboarding/${applicationId}/acceptPaymentPlan`,
            'POST',
            {
                paymentAccountId,
                onboardingConfigModifiedAt: data?.modifiedAt,
            },
        );
    }, [applicationId, data?.modifiedAt]);

    const submitForm = useCallback(async ({ signature }) => {
        return User.authenticatedFetch(
            undefined,
            `/company/onboarding/${applicationId}/submit`,
            'POST',
            {
                signature,
            },
        );
    }, [applicationId]);

    const getSensitiveField = useCallback(async (key) => {
        return User.authenticatedFetch(
            undefined,
            `/company/onboarding/${applicationId}/sensitive`,
            'POST',
            [key],
        );
    }, [applicationId]);

    const uploadFile = useCallback(async ({label, file}) => {
        const formData = new FormData();
        formData.append('ws-ncf-attachment-label', label);
        formData.append('ws-ncf-attachment-file', file);
        return User.authenticatedFetch(
            undefined,
            `/company/onboarding/${applicationId}/attachment`,
            'POST',
            formData,
            { isJson: false },
        );
    }, [applicationId]);

    const removeFile = useCallback(async (id) => {
        return User.authenticatedFetch(
            undefined,
            `/company/onboarding/${applicationId}/attachment/${id}`,
            'DELETE'
        );
    }, [applicationId]);

    const getDownloadUrl = useCallback(async (id) => {
        return User.authenticatedFetch(
            undefined,
            `/company/onboarding/${applicationId}/attachment/${id}`,
            'GET'
        );
    }, [applicationId]);

    const mutation = useMutation(updateApplicationSection, {
        onError: (err, section) => {
            queryClient.setQueryData(applicationQueryKey, {
                ...formData,
                config: {
                    ...formData.config,
                    errorSection: section,
                },
            });
        },
        onSuccess: async (data) => {
            queryClient.setQueryData(applicationQueryKey, () => {
                return {
                    ...data.body,
                };
            });
        },
    });

    const stripeAccountMutation = useMutation(addStripeAccount, {
        onSuccess: async (data) => {
            queryClient.setQueryData(applicationQueryKey, () => {
                return {
                    ...data.body,
                };
            });
        },
    });

    const agreeToPaymentPlanMutation = useMutation(agreeToPaymentPlan, {
        onSuccess: async (data) => {
            queryClient.setQueryData(applicationQueryKey, () => {
                return {
                    ...data.body,
                };
            });
        },
    });

    const submitMutation = useMutation(submitForm, {
        onSuccess: async (data) => {
            queryClient.setQueryData(applicationQueryKey, () => {
                return {
                    ...data.body,
                };
            });
        },
    });

    const linkPaymentAccountMutation = useMutation(linkExistingPaymentAccount, {
        onSuccess: async (data) => {
            queryClient.setQueryData(applicationQueryKey, () => {
                return {
                    ...data.body,
                };
            });
        },
    });

    const updatePaymentAccountMutation = useMutation(updatePaymentAccount, {
        onSuccess: async (data) => {
            queryClient.setQueryData(applicationQueryKey, () => {
                return {
                    ...data.body,
                };
            });
        },
        onError: (err, section, previousValue) => {
            queryClient.setQueryData(applicationQueryKey, {
                ...previousValue,
                config: {
                    ...previousValue.config,
                    errorSection: section,
                },
            });
        },
    });

    const removePaymentAccountMutation = useMutation(removePaymentAccount, {
        onMutate: async (accountId) => {
            await queryClient.cancelQueries(applicationQueryKey);
            const previousValue = queryClient.getQueryData(applicationQueryKey);

            queryClient.setQueryData(applicationQueryKey, old => {
                const paymentAccountFields = {
                    ...old.onboarding.paymentAccounts.fields,
                };
                if (paymentAccountFields.cc?.account?.id === accountId) {
                    delete paymentAccountFields.cc;
                } else if (paymentAccountFields.ach?.account?.id === accountId) {
                    delete paymentAccountFields.ach;
                }

                return {
                    ...old,
                    onboarding: {
                        ...old.onboarding,
                        paymentAccounts: {
                            ...old.onboarding.paymentAccounts,
                            fields: {
                                ...paymentAccountFields,
                            },
                        },
                    },
                };
            });

            return previousValue;
        },
        onSuccess: async (data) => {
            queryClient.setQueryData(applicationQueryKey, () => {
                return {
                    ...data.body,
                };
            });
        },
        onError: (err, section, previousValue) => {
            queryClient.setQueryData(applicationQueryKey, {
                ...previousValue,
                config: {
                    ...previousValue.config,
                    errorSection: section,
                },
            });
        },
        useErrorBoundary: (error) => {
            return error && !error.isError('BadRequestException');
        },
    });

    const addAttachmentMutation = useMutation(uploadFile, {
        onSuccess: async (data) => {
            queryClient.setQueryData(applicationQueryKey, () => {
                return {
                    ...data.body,
                };
            });
        },
        useErrorBoundary: () => {
            return false;
        },
    });

    const removeAttachmentMutation = useMutation(removeFile, {
        onSuccess: async (data) => {
            queryClient.setQueryData(applicationQueryKey, () => {
                return {
                    ...data.body,
                };
            });
        },
    });

    return {
        previewMode,
        companySlug,
        applicationSlug,
        applicationId,
        refetchApplication,
        sellerData: data?.sellerData,
        config: data?.config,
        formData: previewMode ? previewInitialData : formData?.onboarding,
        formArchived: formData?.archived,
        formState: formData?.state,
        formPrimaryUserId: formData?.primaryUserId,
        submittedAt: formData?.submittedAt,
        reviewedAt: formData?.reviewedAt,
        data,
        isLoading: isLoading || (!!applicationId && formIsLoading),
        mutation,
        stripeAccountMutation,
        removePaymentAccountMutation,
        updatePaymentAccountMutation,
        linkPaymentAccountMutation,
        agreeToPaymentPlanMutation,
        addAttachmentMutation,
        removeAttachmentMutation,
        getDownloadUrl,
        submitMutation,
        getSensitiveField,
    };
}
