import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import Testing from 'app/utils/Testing';
import * as BuildInfo from 'app/utils/BuildInfo';
import useActiveUser from 'app/hooks/useActiveUser';
import styles from './ErrorView.module.scss';
import Button from 'app/components/common/Button';
import Conditional from 'app/components/utils/Conditional';
import Modal from 'app/components/common/Modal';
import { connectModal } from 'app/components/utils/ModalProvider';
import TrackedExternalLink from 'app/components/common/ExternalLink/TrackedExternalLink';
import ErrorBackButton from './ErrorBackButton';

import ErrorSvg from 'app/assets/illustrations/error.svg';
import CardDeclinedSvg from 'app/assets/illustrations/card-declined.svg';
import SignOutButton from 'app/components/auth/SignOutButton';
import * as PageView from 'app/analytics/constants/PageView';
import useTrackPageView from 'app/analytics/useTrackPageView';

function ErrorView ({
    showBackButton,
    error,
    withoutNavigation,
    dismiss,
}) {
    const { activeUser } = useActiveUser();

    useTrackPageView({
        type: PageView.Type.Modal,
        entity: PageView.Entity.Error,
        name: error.name,
        error: error.message,
    });

    const errorLookup = {
        StripeCardException: {
            title: error.message?.split(';')[0],
            message: <FormattedMessage
                defaultMessage='You can pay with a checking account or try again later.'
                description='Credit card exception - error message'
            />,
            Illustration: CardDeclinedSvg,
            excludeBackButton: true,
            dismissable: true,
            dismissMessagePrimary: <FormattedMessage
                defaultMessage='Close &amp; use checking account'
                description='Credit card exception - dismiss primary button'
            />,
            dismissMessageSecondary: <FormattedMessage
                defaultMessage='Try again later'
                description='Credit card exception - dismiss secondary button'
            />,
        },
        NotFoundException: {
            title: <FormattedMessage
                defaultMessage='Page not found'
                description='Not found exception - error title'
            />,
            message: <FormattedMessage
                defaultMessage='The page you were looking for could not be found.'
                description='Not found exception - error message'
            />,
            Illustration: ErrorSvg,
        },
        PermissionDeniedException: {
            title: <FormattedMessage
                defaultMessage="You don't have permission to view this page"
                description='Permission denied exception - error title'
            />,
            messageContent: <>
                <p className={styles.messageText}>
                    <FormattedMessage
                        defaultMessage="Unfortunately, you don't have permission to view this page."
                        description='Permission denied exception - error message'
                    />&nbsp;
                    {activeUser?.accountId && <FormattedMessage
                        defaultMessage="You're currently logged in as <b>{accountName}</b>."
                        description='Permission denied exception - error message auth info'
                        values={{
                            accountName: activeUser.accountId,
                        }}
                    />}
                </p>
                <p className={styles.messageText}>
                    <FormattedMessage
                        defaultMessage="Please try logging in with a different email address, or contact {supportEmail} if you need assistance."
                        description='Permission denied exception - error message contact support'
                        values={{
                            supportEmail: <TrackedExternalLink
                                title='support@paywholesail.com'
                                link='mailto:support@paywholesail.com'
                                analyticsElement='support email'
                            />,
                        }}
                    />
                </p>
            </>,
            Illustration: ErrorSvg,
        },
        NetworkException: {
            title: <FormattedMessage
                defaultMessage='No connection'
                description='Network exception - error title'
            />,
            message: <FormattedMessage
                defaultMessage='Go online and try again.'
                description='Network exception - error message'
            />,
            Illustration: ErrorSvg,
            dismissable: true,
        },
    };

    const errorInfo = (error.name && errorLookup[error.name]) || {
        title: <FormattedMessage
            defaultMessage='Something went wrong'
            description='Generic exception - error title'
        />,
        message: <FormattedMessage
            defaultMessage="We're not sure what happened, but we'll try to fix it."
            description='Generic exception - error message'
        />,
        Illustration: ErrorSvg,
    };

    const backButton = showBackButton &&
        !errorInfo.excludeBackButton &&
        <ErrorBackButton onClick={dismiss} />;

    const dismissButtonPrimaryTitle = errorInfo.dismissable &&
        (errorInfo.dismissMessagePrimary || <FormattedMessage
            defaultMessage='Retry'
            description='Default error dimiss button - dismiss primary button'
        />);
    const dismissButtonSecondaryTitle = errorInfo.dismissable &&
        errorInfo.dismissMessageSecondary;
    const generateDismissButton = (title, type) => (
        <Button
            title={title}
            onClick={dismiss}
            analyticsElement={`Dismiss error view ${type}`}
            type={type}
        />
    );
    const dismissButtonPrimary = dismissButtonPrimaryTitle &&
        generateDismissButton(dismissButtonPrimaryTitle, 'primary');
    const dismissButtonSecondary = dismissButtonSecondaryTitle &&
        generateDismissButton(dismissButtonSecondaryTitle, 'secondary');
    const signOutButton = !withoutNavigation && <SignOutButton onClick={dismiss} />;

    const illustration = errorInfo.Illustration && (
        <errorInfo.Illustration />
    );

    return (
        <Modal.Container>
            <Modal.Body className={styles.body}>
                {illustration}
                <h2 {...Testing.testAttributes('error-title')}>
                    {errorInfo.title}
                </h2>
                {!errorInfo.messageContent && <p className={styles.messageText}>
                    {errorInfo.message}
                </p>}
                {errorInfo.messageContent}
                {backButton}
                {dismissButtonPrimary}
                {dismissButtonSecondary}
                {signOutButton}
                <Conditional condition={!BuildInfo.isFullProd()}>
                    <p
                        {...Testing.testAttributes('error-message')}
                        className={styles.errorMessage}
                    >
                        Error: {error.message || error.value}
                    </p>
                </Conditional>
            </Modal.Body>
        </Modal.Container>
    );

}

ErrorView.propTypes = {
    showBackButton: PropTypes.bool.isRequired,
    error: PropTypes.object.isRequired,
    dismiss: PropTypes.func.isRequired,
    withoutNavigation: PropTypes.bool,
};

export { ErrorView };

export default connectModal(ErrorView, {
    analyticsModalType: 'Error view',
});

