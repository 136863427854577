import 'core-js/stable';
import 'regenerator-runtime/runtime';
import { ResizeObserver } from '@juggle/resize-observer';
import { runRouter } from 'app/platform/App';
import 'app/styles/app.scss';
import reportWebVitals from 'app/platform/reportWebVitals';

if (!window.ResizeObserver) {
    window.ResizeObserver = ResizeObserver;
}

// Monitor for unloading to prevent error screen when network request gets cancelled
window.onbeforeunload = function () {
    window.unloading = true;
};

runRouter();
reportWebVitals();
