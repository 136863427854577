import User from 'app/utils/User';
import ActiveCompany from 'app/models/ActiveCompany';

const fetchActiveCompany = async ({ queryKey }) => {
    const [, activeCompanyId] = queryKey;
    const { body } = await User.authenticatedFetch(
        null,
        `/company/config/${activeCompanyId}`,
        'GET',
        undefined,
        { retryable: false },
    );

    return new ActiveCompany(body);
};

export default fetchActiveCompany;
