import PropTypes from 'prop-types';
import cx from 'classnames';
import { FormattedMessage } from 'react-intl';

import Payment from '@wsui/core/models/Payment';

import GlobeSvg from 'app/assets/icons/globe.svg';
import WrenchSvg from 'app/assets/icons/wrench.svg';

import styles from './Badge.module.scss';

export const getPaymentBadges = (className, payment) => {
    const paymentObj = new Payment(payment);
    const isReversed = paymentObj.isReversed();
    const isVoided = paymentObj.isVoided();
    const isVendorCharge = paymentObj.isSellerLinked() || paymentObj.isSellerInitiated();

    let label = '';
    const badges = [];
    if (isReversed) {
        label = <FormattedMessage
            defaultMessage='Reversed'
            description='reversed payment - payment status badge'
        />;
        badges.push(<Badge className={className} level='warning' key='reversed'>
            {label}
        </Badge>);
    } else if (isVoided) {
        label = <FormattedMessage
            defaultMessage='Void'
            description='voided payment - payment status badge'
        />;
        badges.push(<Badge className={className} level='warning'  key='voided'>
            {label}
        </Badge>);
    }

    if (paymentObj.isAutopay()) {
        badges.push(<Badge className={className} level='default'  key='autopay'>
            <FormattedMessage
                defaultMessage='Autopay'
                description='Autopay - payment status badge'
            />
        </Badge>);
    }

    if (isVendorCharge) {
        badges.push(<Badge className={className} level='default' key='vendor-charge'>
            <FormattedMessage
                defaultMessage='Vendor charge'
                description='vendor charge - payment status badge'
            />
        </Badge>);
    }

    return badges;
};

export const PaymentStatusBadge = ({ className, payment }) => {
    const badges = getPaymentBadges(className, payment);
    if (badges.length === 0) {
        return null;
    }

    return badges;
};

export function GlobalBadge ({ className }) {
    return <Badge level='default' className={cx(styles.iconBadge, className)}>
        <GlobeSvg width={10} height={10} />
        <FormattedMessage
            defaultMessage='Global'
            description='Text for global badge'
        />
    </Badge>;
}

export function CustomBadge ({ className }) {
    return <Badge level='attention' className={cx(styles.iconBadge, className)}>
        <WrenchSvg width={10} height={10} />
        <FormattedMessage
            defaultMessage='Custom'
            description='Text for custom badge'
        />
    </Badge>;
}

export default function Badge ({ className, children, level, direction }) {
    return (<span className={styles.badgeHolder}>
        {direction === 'left' && <span className={cx(styles.arrow, styles.left, styles[level])}></span>}
        <small className={cx(styles.badge, styles[level], styles[direction], className)}>
            {children}
        </small>
        {direction === 'right' && <span className={cx(styles.arrow, styles.right, styles[level])}></span>}
    </span>);
}

Badge.propTypes = {
    children: PropTypes.any,
    className: PropTypes.string,
    level: PropTypes.oneOf(['attention', 'default', 'warning', 'promotion']),
    direction: PropTypes.oneOf(['left', 'right']),
};

Badge.defaultProps = {
    level: 'default',
};
